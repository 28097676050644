import React, { Component } from "react";

const UserContext = React.createContext({
//   loggedIn: false,

//   setLoggedIn: () => {}
});

export default UserContext;

export class UserProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false
    };

    this.setLoggedIn = () => {
      this.setState({ loggedIn: !this.state.loggedIn });
    };
  }

  render() {

    
  const value = {
    loggedIn: this.state.loggedIn,
    setLoggedIn: this.setLoggedIn
  };
    return (
      <UserContext.Provider value={value}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
