import React, { Component } from "react";
//import AuthApiService from '../../services/auth-api-service'
import TokenService from "../../Services/token-service";

import {Button} from '../Utils/Utils';
import './Logout.css'
import UserContext from '../../Contexts/UserContext'


/*
Logout is responsible for rendering the Logout page to end users.
*/ 
class Logout extends Component {

  static contextType= UserContext



  handleLogout = event => {
    event.preventDefault();
    TokenService.clearAuthToken();
    
    this.context.setLoggedIn()
     this.props.history.push("/");
    
  };

  render() {
    document.title = "Logout - Overlays";

    return (
      <div className='logout-page'>
        <p>
          <span >Are you sure you want to logout?</span>
          <Button className="logout-cancel" type="submit" onClick={() => this.props.history.goBack()}>
            {" "}
            <i className="fas fa-backspace"></i>
            <span> NO</span>
          </Button>
        </p>

        <form onSubmit={this.handleLogout}>
          <Button type="submit">Logout</Button>
        </form>
      </div>
    );
  }
}

export default Logout;
