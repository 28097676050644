import React ,{Component} from 'react';


import {Link} from 'react-router-dom';
import './Nav.css'
// import TokenService from '../../Services/token-service'
import UserContext from '../../Contexts/UserContext'

/* 
Nav component shows the Login/Logout functionality at the top of every page.
*/
class Nav extends Component{

// static loggedIn=useContext(UserContext)    
static contextType = UserContext


    render(){
        
        
        return(
            

/* <Link id="nav-color" to="/logout">{TokenService.hasAuthToken() ? `Logout` :`Login`}</Link>  */

<Link id="nav-color" to="/logout">{this.context.loggedIn ? `Logout` :`Login`}</Link> 
    
    )




}
}

export default Nav